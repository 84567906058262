import * as React from "react";
import { graphql } from "gatsby";
import {
  GatsbyImage,
  getImage,
  ImageDataLike,
  StaticImage,
} from "gatsby-plugin-image";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import Layout from "../components/Layout";

interface IBlogIndex {
  data: {
    allStrapiArticle: {
      nodes: Array<{
        id: string;
        Slug: string;
        Titre: string;
        publishedAt: String;
        Couverture: Array<{
          caption: string;
          localFile: ImageDataLike;
        }>;
      }>;
    };
  };
}

const BlogIndex = ({ data }: IBlogIndex) => {
  return (
    <Layout
      title="Blog"
      metaTitle="Blog UX Design"
      description="Découvrez à travers nos articles, des réflexions autour de l’UX, des bonnes pratiques sur le design éthique, des retours d’expériences de professionnels"
    >
      <p className="text-center mt-main">
        Retrouvez ici nos réflexions sur des sujets d’actualités, nos do’s, nos
        don’t, et bien d’autres choses.
      </p>

      <div className="mx-10 mt-main">
        {data.allStrapiArticle.nodes.map((article) => {
          const image = getImage(article.Couverture[0]?.localFile);

          return (
            <article
              className="flex my-6 cursor-pointer rounded-2xl shadow-card"
              key={article.id}
              onClick={() => (window.location.href = `/blog/${article.Slug}`)}
            >
              <div className="flex w-40">
                {image ? (
                  <GatsbyImage
                    image={image}
                    alt={article.Couverture[0].caption}
                    className="rounded-l-2xl"
                  />
                ) : (
                  <StaticImage
                    src="../images/photos/blog-internet-ameliorer.png"
                    alt="améliorer son site"
                    className="rounded-l-2xl"
                  />
                )}
              </div>
              <div className="p-4 ml-2">
                <p className="text-sm lg:text-lg">{article.Titre}</p>
                <p className="mt-4 text-sm italic text-gray-400">
                  publié le{" "}
                  {typeof article.publishedAt === "string" &&
                    format(new Date(article.publishedAt), "dd MMMM yyyy", {
                      locale: fr,
                    })}
                </p>
              </div>
            </article>
          );
        })}
      </div>
    </Layout>
  );
};

export default BlogIndex;

export const query = graphql`
  query {
    allStrapiArticle {
      nodes {
        id
        Slug
        Titre
        publishedAt
        Auteurs
        Couverture {
          caption
          localFile {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.77)
            }
          }
        }
      }
    }
  }
`;
